import React, { useState } from 'react';
import { Link } from "react-scroll";

import './header.css';

export const Header = () => {

    const [displayMenu, setDisplayMenu] = useState(false);

    const handleMenuToogle = () => {
        setDisplayMenu(!displayMenu);
    }

    return (
        <nav>
            <div className="header-nav_container">
                <div className="header-logo_container">
                    <img src="./imgs/logo-blanco.png" alt="logo blanco especificalo"/>
                </div>
                <div className="header-menu_container">
                    <button className="btn-menu_toogle" onClick={ handleMenuToogle }>
                        <span className="material-icons">menu</span>
                    </button>
                    <ul 
                        className={ "header-menu_list " + ( displayMenu ? 'menu-block' : 'menu-none' ) } 
                    >
                        <MenuItem to="hero" onClick={ handleMenuToogle } title="Inicio" offset={ -110 }/>
                        <MenuItem to="caracteristics" onClick={ handleMenuToogle } title="Caracteristicas"/>
                        <MenuItem to="register" onClick={ handleMenuToogle } title="Registro"/>
                        <MenuItem to="contacto" onClick={ handleMenuToogle } title="Contacto"/>
                    </ul>
                </div>
            </div>
        </nav>
    )
}


const MenuItem = ( { to , title, offset = -70, onClick } ) => {
    return (
        <>
            <li>
                <Link
                    activeClass="active"
                    onClick={ onClick }
                    to={ to }
                    spy={ true }
                    smooth={ true }
                    offset={ offset }
                    duration={ 500 }
                >{ title }</Link>
            </li>  
        </>
    )
}

