import React, { useEffect, useState } from 'react'
import ScrollAnimation from 'react-animate-on-scroll';

import './hero.css';

export const Hero = () => {

    const [ msgs ] = useState([ 'toda tu empresa', 'todo tu negocio', 'todo tu emprendimiento' ]);

    const [posMsg, setPosMsg] = useState(0)


    useEffect(() => {
        let interval = setInterval(()=>{
            setPosMsg((pos)=>{
                return (pos < msgs.length - 1 ) ? pos + 1 : 0
            });
        },3000);
        return () => {
            clearInterval( interval );
        }
    }, [ msgs ]);

    return (
        <section id="hero">
            <div className="hero-info_container">
                <ScrollAnimation animateIn="fadeInDown">
                    <h1 className="">Muestra { msgs[ posMsg ] } fácil y rápido</h1>
                    <p>No importa qué negocio tengas ni qué vendas.</p>
                    <p>Tendras un perfil completamente autogestionable para que lo acomodes a tu gusto y lo compartas por redes sociales</p>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInUp">
                    <a 
                        className="btn-hero_action" 
                        href="https://especificalo.com/register/company"
                        target="_blank"
                        rel="noreferrer"
                    >
                        !REGISTRATE AHORA!
                    </a>
                    <small>
                        Es completamente <strong>GRATIS</strong>
                    </small>
                </ScrollAnimation>
            </div>
            <div className="hero-img_container">
                <ScrollAnimation className="fadeInRight">
                    <img src="./imgs/hero.png" alt="imagen del home con un perfil de empresa" title="imagen del home con un perfil de empresa"/>
                </ScrollAnimation>
            </div>
        </section>
    )
}
