import React from 'react'

import './caracteristics.css';
import { Caracteristic } from './caracteristic';
import ScrollAnimation from 'react-animate-on-scroll';

export const Caracteristics = () => {
    return (
        <section id="caracteristics">
            <div className="caracteristics-title_container">
                <ScrollAnimation animateIn="fadeInDown">
                    <h2>Caracteristicas</h2>
                    <p>Algunos de los benificios que te ofrecemos</p>
                </ScrollAnimation>
            </div>
            <div className="caracteristics-container">
                <div className="caracteristics-info_left">
                    <Caracteristic
                        animate='fadeInLeft'
                        icon = 'schedule'
                        title = 'Rápido'
                        text = 'Reduces el tiempo de respuesta de tus clientes al tener toda tu información centralizada'
                    />
                    <Caracteristic
                        animate='fadeInLeft'
                        icon = 'smartphone'
                        title = 'Fácil de Usar'
                        text = 'Mostrar tu empresa nunca fue tan fácil. La estructura diseñada guía a tus clientes a una visualización directa, sin importar la edad, habilidades ni conocimientos.'
                    />
                    <Caracteristic
                        animate='fadeInLeft'
                        icon = 'settings'
                        title = 'Autoadministrable'
                        text = 'El perfil es completamente autoadministrable, es decir tu mismo podras hacer los cambios que requieras cuando los requieras, y lo mejor de todo puedes realizarlos desde tu celular o computadora'
                    />
                </div>
                <div className="caracteristics-img_container">
                    <ScrollAnimation animateIn="fadeInUp">
                        <img src="./imgs/caracteristicas.png" alt="imagen del perfil de un cliente" title="imagen del perfil de un cliente"/>
                    </ScrollAnimation>
                </div>
                <div className="caracteristics-info_right">
                    <Caracteristic
                        animate='fadeInRight'
                        icon = 'lock'
                        title = 'Seguro'
                        text = 'Realiza transacciones de manera segura, toda nuestra red esta respaldada con tecnologia de primera. Contamos con certificados de seguridad y proteccion contra malwares y spywares'
                    />
                    <Caracteristic
                        animate='fadeInRight'
                        icon = 'check_circle_outline'
                        title = 'Transparente'
                        text = 'Todas las empresas registradas dentro del portal son 100% reales y verificadas, por ello puedes navegar y preguntar con confianza'
                    />
                    <Caracteristic
                        animate='fadeInRight'
                        icon = 'store'
                        title = 'Online'
                        text = 'Solo debes compartir tu link, y con ello tus clientes tendrán sin necesidad de descargas toda la información de tu empresa al instante y sin preocuparse si estas en horario laboral o no'
                    />
                    
                    
                </div>
            </div>
        </section>
    )
}
