import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'

export const Caracteristic = ({ icon = '',title = '', text = '', animate = 'fadeIn' }) => {
    return (
        <ScrollAnimation animateIn={ animate }>
            <div className="carateristic-item_container ">
                <div className="carateristic-icon_container">
                    <span className="material-icons">{ icon }</span>                
                </div>
                <div className="carateristic-text_container">
                    <h3>{ title }</h3>
                    <p>{ text }</p>
                </div>
            </div>
        </ScrollAnimation>
    )
}
