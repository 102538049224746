import React from 'react'

import './footer.css';

export const Footer = () => {
    return (
        <footer>
            <div className="buttons">
                <a 
                    href="https://wa.me/573113901626/?text=Hola%20me%20interesa%20conocer%20mas%20informacion"
                    target="_blank" rel="noreferrer"
                >
                    <img src="./imgs/whatsapp.webp" width="60px" alt="imagen de whatsapp"/>
                </a>
            </div>
            <p>
                <a 
                    href="https://especificalo.com" 
                    target="_blank" rel="noreferrer"
                >
                    Especificalo 2021
                </a>
            </p>
        </footer>
    )
}
