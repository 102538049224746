import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';

import './register.css';

export const Register = () => {
    return (
        <section id="register">
            <div className="register-title_container">
                <ScrollAnimation animateIn="tada">
                    <h2>Registrate</h2>
                    <p>Comienza a utilizar esta poderosa herramienta en 3 pasos</p>
                </ScrollAnimation>
            </div>
            <div className="register-steps_container">
                <div className="register-step_item">
                    <ScrollAnimation animateIn="fadeInUp">
                        <span>1</span>
                        <p>Registrate</p>
                        <img src="./imgs/register-paso-1.png" alt="imagen del home con señalamiento a la pocision del registro" title="imagen del home con señalamiento a la pocision del registro"/>
                    </ScrollAnimation>
                </div>
                <div className="register-step_item">
                    <ScrollAnimation animateIn="fadeInUp">
                        <span>2</span>
                        <p>Llena tu perfil</p>
                        <img src="./imgs/register-paso-2.png" alt="imagen de un perfil vacio" alt="imagen de un perfil vacio"/>
                    </ScrollAnimation>
                </div>
                <div className="register-step_item">
                    <ScrollAnimation animateIn="fadeInUp">
                        <span>3</span>
                        <p>Comparte tu perfil</p>
                        <img src="./imgs/register-paso-3.png" alt="imagen del perfil de un cliente" title="imagen del perfil de un cliente"/>
                    </ScrollAnimation>
                </div>
            </div>
        </section>
    )
}
