import React from 'react';
import { Header } from '../components/header/header';
import { Hero } from '../components/hero/hero';
import { Caracteristics } from '../components/caracteristics/caracteristics';
import { Register } from '../components/register/register';
import { Contacto } from '../components/contacto/contacto';
import { Footer } from '../components/footer/footer';

export const App = () => {
    return (
        <div style={ { maxWidth:"100vw" , overflowX:"hidden" } }>
            <Header />
            <Hero />
            <Caracteristics />
            <Register />
            <Contacto />
            <Footer />
        </div>
    )
}
