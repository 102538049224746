import React, { useState } from 'react'
import Swal from 'sweetalert2';
import { useSendMail } from '../../hooks/useSendMail';

import './contacto.css';

export const Contacto = () => {

    const initialState = {
        name:'',
        phone: '',
        email:'',
        msg:''
    };

    const [formValues, setFormValues] = useState( initialState );

    const { name, phone, email, msg } =  formValues;

    const [ loading , sendEmail ] = useSendMail();

    const handelInputChange = ({ target }) =>{
        setFormValues({
            ...formValues,
            [ target.name ]: target.value
        })
    }

    const handleSubmitForm = async (e) => {
        e.preventDefault();

        var contentEmail = '<p><b>Nombre:</b> ' + formValues.name +  '</p>';
        contentEmail += '<p><b>Telefono:</b> ' + formValues.phone +  '</p>';
        contentEmail += '<p><b>Correo:</b> ' + formValues.email +  '</p>';
        contentEmail += '<p><b>Mensaje:</b> ' + formValues.msg +  '</p>';

        var email = 'especificalo@gmail.com';
        var date = new Date();

        var data = {
            asunto: 'NUEVO CONTACTO POR LA PAGINA PROMO.ESPECIFICALO ' + date.getTime(),
            mail: contentEmail,
        }

        const resp = await sendEmail( email, data );
        if(resp){
            await Swal.fire('Correo enviado','En un momento uno de nuestro asesores se comunicara contigo','success');
            setFormValues(initialState);    
        }else{
            Swal.fire('Correo no enviado','Lo sentimos ah ocurrido un error porfavor intentalo de nuevo','error');
        }

    }

    return (
        <section id="contacto">
            <div className="contacto-title_container">
                <h2>Contáctanos</h2>
                <p>Si tienes alguna duda o deseas más información puedes preguntar por whatsapp o por el siguiente formulario</p>
            </div>
            <form className="contacto-form" onSubmit={ handleSubmitForm }>
                <div className="contacto-row_input">
                    <div className="input-group">
                        <label>* Nombre</label>
                        <input 
                            placeholder="Tu nombre"
                            name="name"
                            value={ name }
                            onChange={ handelInputChange }
                            required={ true }
                        />
                    </div>
                    <div className="input-group">
                        <label>* Telefono</label>
                        <input 
                            placeholder="Tu telefono"
                            name="phone"
                            required={ true }
                            value={ phone }
                            onChange={ handelInputChange }
                        />
                    </div>
                    <div className="input-group">
                        <label>* Correo</label>
                        <input 
                            placeholder="Tu correo"
                            name="email"
                            required={ true }
                            value={ email }
                            onChange={ handelInputChange }
                        />
                    </div>
                </div>
                <div className="input-group">
                    <label>* Mensaje</label>
                    <textarea
                        value={ msg }
                        name="msg"
                        required={ true }
                        placeholder="Ingresa tu mensaje"
                        onChange={ handelInputChange }
                    ></textarea>
                </div>
                { loading && <p>Enviando Mensaje</p> }
                <p>Al dar click en enviar estas aceptando nuestra <a href="https://especificalo.com/politicas" target="_blank">politica de tratamiento de datos</a></p>
                <button
                    className="btn-contacto_form"
                >Enviar</button>
            </form>
        </section>
    )
}
