import { useState } from 'react';

const baseUrl = 'https://api.especificalo.com';

export const useSendMail = () => {

    const [loading, setLoading] = useState(false);

    const sendEmail = async ( email, data ) => {
        
        var url = `${ baseUrl }/utils/sendmail.php`;
        
        setLoading(true);

        try{
            const token = await fetch(`${ baseUrl }/auth/validate_token.php`).then( r => r.json() );
            //console.log(token);

            data = {
                ...data,
                jwt: token.jwt,
                fun: 'individual',
                para: email,
                save: false,
            }

            var options = {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                body: JSON.stringify( data ),
                
            }

            const resp = await fetch(url, options).then( r => r.json() );
            //console.log(resp);

            setLoading(false);
            return resp;
        }catch(e){
            //console.log(e);
            setLoading(false);
            return null;
        }
    }

    return [ loading, sendEmail ];
}
